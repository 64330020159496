<template>
<div class="shequxiaoqu">
	<div class="form-content transformer shequ">
		<div class="clearfix electric-select" style="margin: 0;">
			<el-form style="display:flex;flex-direction:column" label-position="right" ref="ruleForm" :model="chaxun" class="demo-form-inline" :inline="true">
				<!-- <el-form-item label="社区名称" >
					<el-select   size="small" v-model="chaxun.seachname">
						<el-option
    					  v-for="item in ishequList"
    					  :key="item.levelcode"
    					  :label="item.name"
    					  :value="item.levelcode">
    					</el-option>
					</el-select>
				</el-form-item> -->
				<el-form-item > 
					<!-- <el-button type="primary" @click="search()">
						<i class="el-icon-search"></i>
						查询
					</el-button> -->
					<el-button style="margin-left: 20px;" type="primary" @click="xinzeng()">
						<i class="el-icon-plus"></i>
						新增
					</el-button>
				</el-form-item>
			</el-form>
		</div>
		<div>
			<div v-loading="loading">
				<el-table :data="tableData.list" border style="width: 100%;margin-top: 20px;">
					<el-table-column prop="sore" label="排序" align="center" height show-overflow-tooltip>
					</el-table-column>
					<el-table-column prop="name" label="社区" align="center" height show-overflow-tooltip>
					</el-table-column>
					<el-table-column label="操作" width="300" align="center">
						<template slot-scope="scope">
							<el-button type="primary" size="small" @click="edit(scope.row)"><i class="el-icon-edit"></i> 修改</el-button>
							<el-button type="danger" size="small" @click="shanchu(scope.row.uuid)"><i class="el-icon-delete"></i> 删除</el-button>
						</template>
					</el-table-column>
				</el-table>
				<div class="block">
					<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage" :page-sizes="fenye.pagesizes" :page-size="fenye.pagesize" layout="total, sizes, prev, pager, next, jumper" :total="tableData.totalRow">
					</el-pagination>
				</div>
			</div>

			<el-dialog title="新增" width='650px' :visible.sync="xiangqing1" :close-on-click-modal="false" :before-close="handleClose1">
				<div class="xinzeng" v-loading="loading1" element-loading-text="新增中" element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.8)">
					<el-form ref="form" :model="list1" label-width="100px">
						<div>
							<el-form-item label="社区名称">
								<el-input v-model="list1.levelName" placeholder="请输入社区名称"></el-input>
							</el-form-item>
							<el-form-item label="排序">
								<el-input v-model="list1.sort" placeholder="请输入排序" oninput="value=value.replace(/[^\d]/g,'')"></el-input>
							</el-form-item>
							<el-form-item>
								<el-button type="primary" @click="onSubmit1">立即创建</el-button>
								<el-button @click="close1">取消</el-button>
							</el-form-item>
						</div>

					</el-form>
				</div>
			</el-dialog>
			<el-dialog title="修改" width='650px' :visible.sync="xiangqing2" :close-on-click-modal="false" :before-close="handleClose2">
				<div class="xinzeng" v-loading="loading2" element-loading-text="修改中" element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.8)">
					<el-form ref="form" :model="list1" label-width="100px">
						<div>
							<el-form-item label="社区名称">
								<el-input v-model="list1.levelName" placeholder="请输入社区名称"></el-input>
							</el-form-item>
							<el-form-item label="排序">
								<el-input v-model="list1.sort" placeholder="请输入排序" oninput="value=value.replace(/[^\d]/g,'')"></el-input>
							</el-form-item>
							<el-form-item>
								<el-button type="primary" @click="onSubmit2">修改</el-button>
								<el-button @click="close2">取消</el-button>
							</el-form-item>
						</div>

					</el-form>
				</div>
			</el-dialog>
			<el-dialog :visible.sync="dialogVisible">
				<img width="100%" :src="dialogImageUrl" alt="">
			</el-dialog>
		</div>

	</div>

</div>
	
</template>

<script>
import {shequList,isShequ,addShequ,updateShequ,removeShequ} from '@/api/shequ/shequ.js'
	export default {
		data() {
			return {
				ishequList:[],//可查社区列表
				chaxun: {
					seachname:'',
				},
				tableData: [],
				show: false,
				text: '展开',
				isIndeterminate: true,
				duoxuanList: [],
				checkList: [],
				list1: {
					uuid: '',
					levelName:'',
					sort: ''
				},
				fenye: {
					pagesizes: [5, 10, 15, 20, 25, 30],
					pagesize: 10,
					totalnum: 9
				},
				currentPage: 1,
				labelcode: '',
				historyData: [],
				radio: '0',
				loading: true,
				xiangqing1: false,
				xiangqing2: false,
				loading1: false,
				loading2: false,
				list2: {
					parameter: '',
					rulefast: '',
					rulelaw: '',
					sort: '',
					bigintid: ''
				},
				dupinlist: [],
				selected: '',
				xiangqing3: false,
				tableData1: [],
				daoruVisible: false,
				form3: {
					supercode: '',
					wenjian: ''
				},
				loading3: false,
				fileList1: [],
				dialogImageUrl: '',
				dialogVisible: false,
				wenjian: [],
				fileList2: [],
				msgpic: '',
				schoolList:[]
			}
		},
		methods: {
			xinzeng(){
				var that = this;
				
				that.xiangqing1 = true;
			},
			
			daoru() {
				var that = this;
				that.daoruVisible = true;

			},
			
			guanbi() {
				this.form3 = {
					wenjian: '',
					supercode: ''
				};
				this.$refs.upload.clearFiles();
				this.daoruVisible = false;
			},
			
			handleSizeChange(val) {
				// console.log(`每页 ${val} 条`);
				this.fenye.pagesize = val;
				this.currentPage = 1;
				this.getData();
			},
			handleCurrentChange(val) {
				this.currentPage = val;
				this.getData();
			},
			search() {
				this.currentPage = 1;
				this.getData();
			},
			handleCommand(command) {
				this.chaxun.command = command
				this.chaxun.date2 = [];
				this.chaxun.date1 = ""
			},
			getData() {
				var that = this;
				that.loading = true;
				let seachname=that.chaxun.seachname
				let data = {
					userid: that.GLOBAL.adminId(),
					levelcode:seachname,
					page: that.currentPage,
					pagesize: that.fenye.pagesize,
				}
				shequList(data).then(res=>{
					this.loading = false;
					if(res.result == '200'){
						this.tableData = res.detail;
						this.fenye.totalnum = res.detail.totalRow;
					}else{
						this.$message.error(res.description);
					}
				})
				
			},
			handleClose1: function(done) {
				this.close1();
				done();
			},
			close1() {
				this.xiangqing1 = false;
				this.list1 = {
					uuid: '',
					levelName:'',
					sort: ''
				};
				this.fileList1 = [];
			},
			onSubmit1() {
				var that = this;
				if (that.list1.levelName.trim() == '') {
					this.$message.warning('请填写社区名称');
					return;
				}
				if (that.list1.sort == '') {
					this.$message.warning('请填写排序');
					return;
				}
				that.loading1 = true;
				addShequ({
						userid: that.GLOBAL.adminId(),
						levelName: that.list1.levelName,
						sort: that.list1.sort,
					}).then(res=>{
						that.loading1 = false;
						if(res.result==200){
							that.$message.success('新增成功');
							that.close1();
							that.getData();
						}else{
							this.$message.error(res.description);
						}
					})
			},
			shanchu(a) {
				var that = this;
				that.$confirm('是否确认删除该条数据？', '提示', {
					confirmButtonText: '确认',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					removeShequ({
							userid: that.GLOBAL.adminId(),
							uuid: a
						}).then(res=>{
							if(res.result==200){
								that.$message({
									type: 'success',
									message: '删除成功'
								});
								that.getData();
							}else{
								that.$message.error(res.description);
							}
						})
				}).catch(() => {

				});
			},
			edit(a) {
				var that = this;
				that.list1 = {
					uuid: a.uuid,
					levelName:a.name,
					sort: a.sore
				};
				that.xiangqing2 = true;

			},
			handleClose2: function(done) {
				this.close2();
				done();
			},
			close2() {
				this.xiangqing2 = false;
				this.list1 = {
					uuid: '',
					levelName:'',
					sort: ''
				};
				this.wenjian = [];
				this.msgpic = '';
			},
			onSubmit2() {
				var that = this;
				if (that.list1.levelName.trim() == '') {
					this.$message.warning('请填写社区名称');
					return;
				}
				if (that.list1.sort == '') {
					this.$message.warning('请填写排序');
					return;
				}
				that.loading2 = true;
				updateShequ({
						userid: that.GLOBAL.adminId(),
						uuid: that.list1.uuid,
						levelName: that.list1.levelName,
						sort: that.list1.sort,
					}).then(res=>{
						that.loading2 = false;
						if(res.result==200){
							that.$message.success('修改成功');
							that.close2();
							that.getData();
						}else{
							this.$message.error(res.description);
						}
					})
			},
		},
		mounted() {
			// isShequ({userid:this.GLOBAL.adminId()}).then(res=>{
			// 	if(res.result==200){
			// 		res.detail.map(item=>{
			// 			this.chaxun.seachname.push(item.levelcode)
			// 		})
			// 		this.ishequList=res.detail
					this.getData();
			// 	}else{
			// 		this.$message.error(res.description)
			// 	}
			// })
		}
	}
</script>
<style  lang='scss'>
	.shequxiaoqu{
		
.el-form-item {
	font-size: 14px;
}
.el-input__inner {
	height: 32px;
	line-height: 32px;
	padding: 0 16px;
	display: block;
}

.el-input__icon {
	line-height: 32px;
}

.el-button {
	padding: 0 15px;
	height: 32px;
	line-height: 30px;
}

.el-table {
	border-top: 1px solid #d8dce8;
}

.form-content {
	border: 1px solid #d7dde4;
	padding: 18px;
	box-sizing: border-box;
	background-color: #fff;
	min-height: 100%;
	min-width: 1200px;
}
.el-table::before {
	height: 0px !important;
}
td,
.el-table__header-wrapper {
	border-bottom: 1px solid #d8dce8;
}
th {
	border-bottom: none !important;
}
.transformer th {
	padding: 8px 0;
}
.transformer .cell {
	font-size: 14px;
	padding: 0 16px;
	color: #696b75;
	/* text-align: center; */
	font-family: MicrosoftyaHei;
}
.transformer th .cell {
	line-height: 24px !important;
}
.transformer td {
	padding: 4px 0;
}
th .cell {
	font-weight: 700;
}
.button {
	margin-top: 20px;
	overflow: hidden;
}
.button button {
	float: right;
}
.el-select {
	width: 100%;
}
.el-date-editor.el-input {
	width: 100% !important;
}
.amap-simple-marker-label {
	color: #fff !important;
}
.department .cell {
	text-align: left;
}
.department .is-leaf .cell {
	text-align: center !important;
}
.electric-select {
	/* margin-bottom: 20px; */
}
.electric-select p {
	float: left;
	line-height: 32px;
	font-size: 14px;
	color: #606266;
	margin: 0 0 20px;
}
.el-date-editor .el-range__icon,
.el-date-editor .el-range-separator{
	line-height: 24px !important;
}
.el-header{
	padding: 0;
}
.btn-custom-cancel {
  float: right;
  margin-left: 10px;
}
.electric-select .el-date-editor .el-range__close-icon{
	line-height: 24px !important;
}
.shangchuan .el-form-item__content{
	height: auto !important;
}
.el-input__suffix{
	line-height: 32px !important;
}
.el-date-editor .el-range__close-icon{
	line-height: 24px !important;
}
.hide .el-upload--picture-card {
	display: none;
}
.hide .el-upload-list--picture-card .el-upload-list__item-actions .el-upload-list__item-delete{
	display: none;
}
.el-dialog__body{
	padding: 20px !important;
}
.el-input__prefix{
	line-height: 32px !important;
}
.my-img {
    width: 100px;
    height: 100px;
}
.my-img .el-image__inner,
.el-image__error {
    visibility: hidden;
}
.el-image-viewer__wrapper{
	z-index: 999999999999999 !important;
}
.el-image-viewer__close{
	color: #fff;
}
		.el-select__tags{
			max-width: 100% !important;
			span{
				display: flex;
			}
		}
		
	}
</style>
<style scoped="scoped" lang='scss'>
	.searchDetail {
		margin-top: 20px;
	}

	.selectContent td {
		font-size: 14px !important;
		padding: 10px;
		box-sizing: border-box;
		border-right: 1px solid #d8dce8;
		border-bottom: 1px solid #d8dce8;
	}

	.selectContent table {
		border-top: 1px solid #d8dce8;
		border-left: 1px solid #d8dce8;
		margin: 10px 0;
	}

	.selectContent .two,
	.selectContent .three {
		width: 33%;
	}

	.block {
		text-align: right;
	}

	/* .clearfix ::v-deep .el-form--inline .el-form-item {
		margin-right: 0;
	} */


	.clearfix ::v-deep .el-date-editor {
		/* width: 150px !important; */
		margin-right: 20px;
	}

	.clearfix ::v-deep .el-select {
		width: 76.8vw !important;
	}
	

	.clearfix ::v-deep .el-range__icon,
	.clearfix ::v-deep .el-range-separator {
		line-height: 24px;
	}

	.biaoqian {
		box-sizing: border-box;
		border-top: 1px solid #666;
		border-left: 1px solid #666;

	}

	.biaoqian>div {
		overflow: hidden;
	}

	.biaoqian>div>div {
		float: left;
		border-right: 1px solid #666;
		border-bottom: 1px solid #666;
		box-sizing: border-box;
		height: 41px;
		line-height: 40px;
		padding: 0 10px;
	}

	.biaoqian>div>div:first-child {
		width: 200px;
	}

	.biaoqian>div>div:last-child {
		width: calc(100% - 200px);
	}
</style>
